import React from "react";
import AccordionWithHeader from "../../components/Accordion/AccordionWithHeader";
import {Container, Row} from "react-grid-system";
import {AccordionItem} from "../../components";
import {StateOfResidencyAccordionItem} from "./StateOfResidencyAccordionItem";
import {StateInputDto, StateOfResidencySectionInfo} from "../WealthPOTypes";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {getStateNameAndRateFromStateAbbreviation} from "../WealthPOUtils";
import {StateOfResidencyAccordionHeader} from "./StateOfResidencyAccordionHeader";

interface StateOfResidencyOverviewProps {
    isInReportPreview: boolean;
    listOfStates: StateInputDto[];
}

const StateOfResidencyOverview: React.FC<StateOfResidencyOverviewProps> = ({
                                                                               isInReportPreview,
                                                                               listOfStates
                                                                           }: StateOfResidencyOverviewProps) => {
    const profileStateAbbr = useAppSelector(selectProfile).primaryContact.stateAbbr;
    const profileSOR = getStateNameAndRateFromStateAbbreviation(listOfStates, profileStateAbbr!)

    const profileStateOfResidency: StateOfResidencySectionInfo = {
        id: undefined,
        description: profileStateAbbr ? "Profile State of Residency" : "––",
        selectedStateOfResidency: (profileSOR === "" || profileSOR === undefined) ? "––" : profileSOR,
        estimatedImpact: {
            amountToBeneficiaries: 0,
            impactToEstEstateTax: 0,
            strategyPayoutSchedule: []
        },
        enabled: true,
        effectiveRate: undefined
    }

    return (
        <Container className="state-of-residency-overview" data-testid="state-of-residency-overview">
            <h4>State of Residency</h4>
            <AccordionWithHeader
                accordionId={'state-of-residency-accordion'}
                allItems={[]}
                columnTitles={['Name', 'State', 'Effective Rate %']}
                renderHeader={true}
                HeaderComponent={() => StateOfResidencyAccordionHeader()}
                ignoreExpandedItems={true}
            >
                <Row className="state-of-residency-overview__accordion-row" data-testid="profile-SOR-row">
                    <AccordionItem
                        key={"profile-state-of-residency"}
                        uuid={"profile-state-of-residency"}
                        HeaderComponent={() => StateOfResidencyAccordionItem(
                            {
                                description: profileStateOfResidency.description,
                                isProfileSOR: true,
                                stateOfResidency: profileStateOfResidency.selectedStateOfResidency,
                                effectiveRate: profileStateOfResidency.effectiveRate,
                                enabled: profileStateOfResidency.enabled,
                                isInReportPreview: isInReportPreview
                            }
                        )}
                        expandable={false}
                    />
                </Row>
            </AccordionWithHeader>
        </Container>
    );
}

export default StateOfResidencyOverview;