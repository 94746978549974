import React, {useCallback, useEffect, useState} from "react";
import {Col, Row} from "react-grid-system";
import BarChartLegend, {barChartLegend} from "../../components/Legend/BarChartLegend";
import {Dropdown, DropdownItem} from "../../components";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {getPlanSummary} from "../PlanSummary/planSummarySlice";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import {COLOR_COMPARE_PLANS_EXCESS, COLOR_COMPARE_PLANS_SHORTFALL, COLOR_NT_GREY} from "../../constants/colors";
import "../../styles/pages/_asset-reliance-barchart.scss";
import {emptyInvestorGroup, InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import Highcharts from "highcharts";
import {calculateCNWInEstateTotalValue} from "../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {CreatePlanSummaryNetWorthOverTimeGraphOptions} from "../PlanSummary/PlanSummaryNetWorthOverTimeGraphUtils";
import {AssetsSummary, emptyAssetsSummary} from "../../Assets/models/Assets";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import NetWorthOverTimeRow from "./NetWorthOverTimeRow";
import {selectClientAssets, setClientAssets} from "../../Assets/clientAssetsSlice";
import ComparePlansRiskDonut from "./ComparePlansRiskDonut";
import ReserveTargetLengthRow from "./ReserveTargetLengthRow";
import {emptyEstateSummary, EstateSummary, EstateType} from "../../WealthTransfer/models/api";
import {wealthTransferApiClient} from "../../WealthTransfer/WealthTransferApiClient";
import EstimatedEstateTaxRow from "./EstimatedEstateTaxRow";
import {formatCurrency, formatNumberRoundedToWholeNumber, roundUpToNext, truncateCurrency} from "../../utils/format";
import PillTextComponent from "./PillTextComponent";
import ChartStatusLine from "../../ClientManagement/AssetReliance/ChartStatusLine";
import {ComparePlansBarchart} from "./ComparePlansBarchart";
import _ from "lodash";
import {
    resetComparePlan2SelectedId,
    resetComparePlan2SummaryResponse,
    selectComparePlan1SelectedId,
    selectComparePlan1SummaryResponse,
    selectComparePlan2SelectedId,
    selectComparePlan2SummaryResponse,
    selectComparePlanId,
    selectDifferenceBetweenPlans,
    setComparePlan1SelectedId,
    setComparePlan1SummaryResponse,
    setComparePlan2SelectedId,
    setComparePlan2SummaryResponse,
    setComparePlanId
} from "./ComparePlansSlice";
import {useSelector} from "react-redux";

interface ComparePlansContentProps {
    value: boolean;
    onChange: (isChecked: boolean) => void;
}

const ComparePlansContent: React.FC<ComparePlansContentProps> = ({value, onChange}) => {
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const {proposals, approvedProfile} = useProfileAndProposals(id);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [comparePlan1investorGroup, setComparePlan1investorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const [comparePlan2InvestorGroup, setComparePlan2InvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const clientAssets: AssetsSummary | undefined = useAppSelector(selectClientAssets);
    const [comparePlan2ClientAssets, setComparePlan2ClientAssets] = useState<AssetsSummary | undefined>(emptyAssetsSummary);
    const [comparePlan1PortfolioReserveTargetLength, setComparePlan1PortfolioReserveTargetLength] = useState<number | undefined>(undefined);
    const [comparePlan2PortfolioReserveTargetLength, setComparePlan2PortfolioReserveTargetLength] = useState<number | undefined>(undefined);
    const [comparePlan1EstateSummary, setComparePlan1EstateSummary] = useState<EstateSummary>(emptyEstateSummary);
    const [comparePlan2EstateSummary, setComparePlan2EstateSummary] = useState<EstateSummary>(emptyEstateSummary);
    const showDeltaBetweenPlans = useSelector(selectDifferenceBetweenPlans);

    const handleComparePlanDropDownChange = onChange;
    const updateComparePlan1SelectedId = (response: string) => {
        dispatch(setComparePlan1SelectedId(response));
    };
    const updateComparePlan2SelectedId = (response: string | null) => {
        dispatch(setComparePlan2SelectedId(response));
    };
    const updateComparePlanId = (response: string) => {
        dispatch(setComparePlanId(response));
    }

    const comparePlan1SelectedId = useSelector(selectComparePlan1SelectedId);
    const comparePlan2SelectedId = useSelector(selectComparePlan2SelectedId);
    const previouslySelectedComparePlanId = useSelector(selectComparePlanId);

    const updateComparePlan1SummaryResponse = (response: PlanSummaryResponse) => {
        dispatch(setComparePlan1SummaryResponse(response));
    };
    const updateComparePlan2SummaryResponse = (response: PlanSummaryResponse) => {
        dispatch(setComparePlan2SummaryResponse(response));
    };
    const comparePlan1SummaryResponse = useSelector(selectComparePlan1SummaryResponse);
    const comparePlan2SummaryResponse = useSelector(selectComparePlan2SummaryResponse);

    const fetchData = useCallback(async (proposalId: string): Promise<PlanSummaryResponse | undefined> => {
        setIsLoading(true);
        const response = await dispatch(getPlanSummary(proposalId));
        if (response.payload) {
            const [
                newInvestorGroup,
                assetSummaryResponse,
                estateSummaryResponse,
                profileResponse
            ] = await Promise.all([
                clientManagementApiClient.getInvestorGroup(proposalId),
                assetsApiClient.getAssetsSummary(proposalId),
                wealthTransferApiClient.getEstateSummary(proposalId, EstateType.CURRENT),
                clientManagementApiClient.getProfile(proposalId)
            ]);
            setComparePlan1investorGroup(newInvestorGroup);
            dispatch(setClientAssets(assetSummaryResponse));
            setComparePlan1EstateSummary(estateSummaryResponse);
            setComparePlan1PortfolioReserveTargetLength(profileResponse.portfolioReserveTargetLength);
            setComparePlan1SummaryResponse(response.payload as PlanSummaryResponse);
            setComparePlan1SelectedId(proposalId);
            setComparePlanId(id);
            setIsLoading(false);
            return response.payload as PlanSummaryResponse;
        }
    }, [dispatch]);

    const fetchAndUpdatePlanData = async (proposalId: string) => {
        const response = await fetchData(proposalId);
        if (response) {
            updateComparePlan1SummaryResponse(response);
            updateComparePlan1SelectedId(proposalId);
            updateComparePlanId(id);
        }
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (comparePlan1SelectedId && previouslySelectedComparePlanId === id) {
                fetchAndUpdatePlanData(comparePlan1SelectedId);
            } else {
                fetchAndUpdatePlanData(id);
                dispatch(resetComparePlan2SummaryResponse());
                dispatch(resetComparePlan2SelectedId());
            }
        }
        return () => {
            isMounted = false;
        };
    }, [comparePlan1SelectedId]);

    useEffect(() => {
        let isMounted = true;
        (async () => {
            if (comparePlan2SelectedId != null) {
                setIsLoading(true);
                const response = await dispatch(getPlanSummary(comparePlan2SelectedId));
                if (isMounted && response.payload) {
                    Promise.all([
                        clientManagementApiClient.getInvestorGroup(comparePlan2SelectedId),
                        assetsApiClient.getAssetsSummary(comparePlan2SelectedId),
                        wealthTransferApiClient.getEstateSummary(comparePlan2SelectedId, EstateType.CURRENT),
                        clientManagementApiClient.getProfile(comparePlan2SelectedId)
                    ]).then(([newInvestorGroup,
                                 assetSummaryResponse,
                                 estateSummaryResponse,
                                 proposalResponse]) => {
                        setComparePlan2InvestorGroup(newInvestorGroup);
                        setComparePlan2ClientAssets(assetSummaryResponse);
                        setComparePlan2EstateSummary(estateSummaryResponse);
                        setComparePlan2PortfolioReserveTargetLength(proposalResponse.portfolioReserveTargetLength);
                        setIsLoading(false);
                    });
                    updateComparePlan2SummaryResponse(response.payload as PlanSummaryResponse);
                    updateComparePlan2SelectedId(comparePlan2SelectedId)
                    setComparePlan2SummaryResponse(response.payload as PlanSummaryResponse);
                    setComparePlan2SelectedId(comparePlan2SelectedId)
                    handleComparePlanDropDownChange(true);
                }
            }
        })();
        return () => {
            isMounted = false;
        };
    }, [comparePlan2SelectedId]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    const calculateNetAssets = (summaryResponse: PlanSummaryResponse | undefined) => {
        return (summaryResponse?.totalNetValue ?? 0) + (summaryResponse?.totalAssetPurchaseValue ?? 0);
    };

    const calculateDifference = (value1: number, value2: number) => {
        return value2 - value1;
    };

    const netAssetsComparePlan1 = calculateNetAssets(comparePlan1SummaryResponse);
    const netAssetsComparePlan2 = calculateNetAssets(comparePlan2SummaryResponse!);

    const totalGoalsComparePlan1 = comparePlan1SummaryResponse?.goalsTotalPresentValue ?? 0;
    const totalGoalsComparePlan2 = comparePlan2SummaryResponse?.goalsTotalPresentValue ?? 0;

    const excessAssetsComparePlan1 = comparePlan1SummaryResponse?.excessAssets ?? 0;
    const excessAssetsComparePlan2 = comparePlan2SummaryResponse?.excessAssets ?? 0;

    let totalRiskAssetsPercentPlan1 = comparePlan1SummaryResponse?.proposedAllocation.totalRiskAssetsPercent ?? 0;
    totalRiskAssetsPercentPlan1 = Number(formatNumberRoundedToWholeNumber(totalRiskAssetsPercentPlan1));
    let totalRiskAssetsPercentPlan2 = comparePlan2SummaryResponse?.proposedAllocation.totalRiskAssetsPercent ?? 0;
    totalRiskAssetsPercentPlan2 = Number(formatNumberRoundedToWholeNumber(totalRiskAssetsPercentPlan2));

    const differenceInNetAssets = calculateDifference(netAssetsComparePlan1, netAssetsComparePlan2);
    const differenceInTotalGoals = calculateDifference(totalGoalsComparePlan1, totalGoalsComparePlan2);
    const differenceInExcessAssets = calculateDifference(excessAssetsComparePlan1, excessAssetsComparePlan2);
    const differenceInTotalRiskPercent = calculateDifference(totalRiskAssetsPercentPlan1, totalRiskAssetsPercentPlan2)
    const differenceInPortfolioReserveTargetLength = calculateDifference(comparePlan1PortfolioReserveTargetLength!, comparePlan2PortfolioReserveTargetLength!)

    const isEmptyState = comparePlan2SummaryResponse == null;

    const differenceInEstimatedEstateTax = calculateDifference(
        comparePlan1EstateSummary.estimatedEstateTax,
        comparePlan2EstateSummary.estimatedEstateTax
    );

    const futureValueOfExcessAssetsComparePlan1 = comparePlan1SummaryResponse?.futureValueOfExcessAssets ?? 0;
    const futureValueOfExcessAssetsComparePlan2 = comparePlan2SummaryResponse?.futureValueOfExcessAssets ?? 0;

    const chartLegend =
        <div>
            <div className="compare-plans-sub-header">Excess Assets</div>
            <div className="bar-chart-legend-container">
                <div className="asset-reliance-barchart-legend-label">
                    <BarChartLegend legend={barChartLegend.NET_ASSETS} label={"ASSETS"}/>
                </div>
                <div className="asset-reliance-barchart-legend-label">
                    <BarChartLegend legend={barChartLegend.GOALS} label={"GOALS"}/>
                </div>
                {(excessAssetsComparePlan1 < 0 || excessAssetsComparePlan2 < 0) &&
                    <div className="asset-reliance-barchart-legend-label">
                        <BarChartLegend legend={barChartLegend.ASSET_SHORTFALL} label={"ASSET SHORTFALL"}/>
                    </div>
                }
                {(excessAssetsComparePlan1 > 0 || excessAssetsComparePlan2 > 0) &&
                    <div className="asset-reliance-barchart-legend-label">
                        <BarChartLegend legend={barChartLegend.EXCESS_ASSETS} label={"EXCESS ASSETS"}/>
                    </div>
                }
            </div>
        </div>;

    const minimumValueToApplyGraphRounding = 1_000_000;
    const getMaxValue = (value1: number, value2: number) => Math.max(value1, value2);
    const getChartUnderlayMaxValue = (underLayValue: number) =>
        underLayValue >= minimumValueToApplyGraphRounding ? roundUpToNext(underLayValue, 5) : minimumValueToApplyGraphRounding;

    const calculateNetWealthAtCurrentAge = (assets: AssetsSummary, totalTaxLiabilities: number) =>
        calculateCNWInEstateTotalValue(assets) + totalTaxLiabilities;

    const createGraphOptions = (investorGroup: InvestorGroupType, netWealthAtCurrentAge: number, futureValueOfExcessAssetsAtAge: any) =>
        CreatePlanSummaryNetWorthOverTimeGraphOptions({
            investorGroup,
            netWealthAtCurrentAge,
            futureValueOfExcessAssetsAtAge,
        });

    const barchartMaxValueComparePlan1 = getMaxValue(totalGoalsComparePlan1, netAssetsComparePlan1);
    const barchartMaxValueComparePlan2 = getMaxValue(totalGoalsComparePlan2, netAssetsComparePlan2);
    const chartUnderlayMaxValueComparePlans = getChartUnderlayMaxValue(getMaxValue(barchartMaxValueComparePlan1, barchartMaxValueComparePlan2));
    const totalTaxLiabilitiesForAllAccounts = clientAssets!.totalTaxLiabilities.totalPresentTaxLiabilityForAllAccounts ?? 0;
    const comparePlan1NetWealthAtCurrentAge = calculateNetWealthAtCurrentAge(clientAssets!, totalTaxLiabilitiesForAllAccounts);
    const comparePlan1GraphOptions: Highcharts.Options = createGraphOptions(
        comparePlan1investorGroup,
        comparePlan1NetWealthAtCurrentAge,
        comparePlan1SummaryResponse!.futureValueOfExcessAssetsByAge
    );
    const comparePlan2TotalTaxLiabilitiesForAllAccounts = comparePlan2ClientAssets!.totalTaxLiabilities.totalPresentTaxLiabilityForAllAccounts ?? 0;
    const comparePlan2NetWealthAtCurrentAge = calculateNetWealthAtCurrentAge(comparePlan2ClientAssets!, comparePlan2TotalTaxLiabilitiesForAllAccounts);
    const comparePlan2GraphOptions: Highcharts.Options = createGraphOptions(
        comparePlan2InvestorGroup,
        comparePlan2NetWealthAtCurrentAge,
        comparePlan2SummaryResponse?.futureValueOfExcessAssetsByAge ?? []
    );
    const renderDropdownItems = (comparePlanProposals: any[]) => {
        return comparePlanProposals.map(proposal => (
            <DropdownItem key={proposal.id} value={proposal.id}
                          itemText={proposal.displayName}
                          style={{paddingLeft: '35px'}}
            />
        ));
    };

    const renderChartStatusLines = (barChartColWidth: number, chartUnderlayMaxValue: number) => {
        return _.range(6).map((index) => {
            const verticalOffset = `${(index * 20)}`;
            const lineWidth = (2 <= 1 ? 6 : 2) * barChartColWidth;
            const labelNumber = truncateCurrency((chartUnderlayMaxValue * ((5 - index) * 0.2)), {
                formatAsCurrency: false,
                includeUnits: true,
                maxValue: chartUnderlayMaxValue
            });
            return (
                <div key={index}>
                    <ChartStatusLine
                        id={'barChartUnderlay'}
                        label={`${labelNumber}`}
                        height={'1px'}
                        zIndex={0}
                        width={`calc(${lineWidth}px + 100%)`}
                        lineColor={COLOR_NT_GREY}
                        verticalOffset={verticalOffset}
                        marginLeft={0}
                        textColor={''}
                        isAssetReliance={true}
                    />
                </div>
            );
        });
    };

    const renderComparePlansBarchart = (netAssets: number, totalGoals: number, excessAssets: number, chartUnderlayMaxValue: number) => {
        return (
            <ComparePlansBarchart
                totalStacks={2}
                netAssets={netAssets}
                totalGoals={totalGoals}
                excessAssets={excessAssets}
                chartUnderlayMaxValue={chartUnderlayMaxValue}
            />
        );
    };

    const renderComparePlansContent = (comparePlanSummaryResponse: PlanSummaryResponse | null, netAssetsComparePlan: number, totalGoalsComparePlan: number, excessAssetsComparePlan: number, chartUnderlayMaxValuePlan: number) => {
        return comparePlanSummaryResponse ? (
            renderComparePlansBarchart(netAssetsComparePlan, totalGoalsComparePlan, excessAssetsComparePlan, chartUnderlayMaxValuePlan)
        ) : (
            <div className='compare-plans-empty-state'>Select a plan to compare</div>
        );
    };

    const renderPillTextComponent = (differenceValue: number) => {
        const color = differenceValue >= 0 ? COLOR_COMPARE_PLANS_EXCESS : COLOR_COMPARE_PLANS_SHORTFALL;
        const className = differenceValue >= 0 ? "compare-plans-pill-container pill-container-excess" : "compare-plans-pill-container pill-container-shortfall";

        return (
            <PillTextComponent
                color={color}
                pillTextValue={differenceValue}
                className={className}
            />
        );
    };

    const getExcessAssetsClassName = (excessAssets1: number, excessAssets2: number) => {
        return (excessAssets1 < 0 && excessAssets2 <= 0) ? "shortfall-accent" : "excess-accent";
    };

    const renderTableContent = (
        comparePlanContent: number,
        showDelta: boolean,
        difference: number
    ) => {
        return (
            <div>
                {formatCurrency(comparePlanContent)}
                {showDelta && difference != 0 && renderPillTextComponent(difference)}
            </div>
        );
    };

    const getClassName = (excessAssetsComparePlanValue: number) => {
        return excessAssetsComparePlanValue < 0 ? 'accordion-column negative-difference' : 'accordion-column';
    };

    return (
        <main className="compare-plans-content">
            <Row className={"row-styling"} style={{paddingRight: '40px'}}>
                <div className="row-container" style={{paddingBottom: "15px"}}>
                    <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: '0'}}>
                        {chartLegend}
                    </Col>
                    <span className="divider"></span>
                    <Col md={CHART_TITLE_CELL_WIDTH}>
                        <div data-testid="select-compare-plan1-label">
                            <Dropdown
                                className="compare-plans-dropdown"
                                label="Select a plan"
                                inlineLabel
                                panelHeight="auto"
                                panelWidth="300px"
                                size='medium'
                                value={comparePlan1SelectedId ?? id}
                                onChange={(e: { value: string }) => {
                                    updateComparePlan1SelectedId(e.value);
                                }}>
                                <DropdownItem key={approvedProfile.id} value={approvedProfile.id}
                                              itemText={approvedProfile.displayName}
                                              style={{paddingLeft: '35px'}}
                                />
                                {renderDropdownItems(proposals)}
                            </Dropdown>
                        </div>
                    </Col>
                    <span className="divider"></span>
                    <Col md={CHART_TITLE_CELL_WIDTH}>
                        <div data-testid="select-compare-plan2-label">
                            <Dropdown
                                className="compare-plans-dropdown"
                                label="Select a plan"
                                inlineLabel
                                panelHeight="auto"
                                panelWidth="300px"
                                size='medium'
                                disabled={proposals.length === 0}
                                value={comparePlan2SelectedId}
                                onChange={(e) => {
                                    updateComparePlan2SelectedId(e.value)
                                }}>
                                <DropdownItem key={approvedProfile.id} value={approvedProfile.id}
                                              itemText={approvedProfile.displayName}
                                              style={{paddingLeft: '35px'}}
                                />
                                {renderDropdownItems(proposals)}
                            </Dropdown>
                        </div>
                    </Col>
                </div>
            </Row>
            <Row className={"row-styling"} style={{height: '150px'}}>
                <div className={"row-container"}>
                    <Col style={{width: '374px', flex: 'unset'}}>
                        {renderChartStatusLines(0, chartUnderlayMaxValueComparePlans)}
                    </Col>
                    <span className={"divider"}></span>
                    <Col md={CHART_TITLE_CELL_WIDTH}>
                        {renderComparePlansBarchart(netAssetsComparePlan1, totalGoalsComparePlan1, excessAssetsComparePlan1, chartUnderlayMaxValueComparePlans)}
                    </Col>
                    <span className={"divider"}></span>
                    <Col md={CHART_TITLE_CELL_WIDTH}>
                        {renderComparePlansContent(comparePlan2SummaryResponse, netAssetsComparePlan2, totalGoalsComparePlan2, excessAssetsComparePlan2, chartUnderlayMaxValueComparePlans)}
                    </Col>
                </div>
            </Row>
            <Row className={"row-styling-accordion"} style={{marginTop: "40px"}}>
                <div className={"row-container"}>
                    <Col className={"accordion-header"} width={TITLE_CELL_WIDTH}
                         style={{paddingLeft: 0, borderLeft: "15px solid #3b9fa5"}}>
                        Net Assets
                    </Col>

                    <Col md={CHART_TITLE_CELL_WIDTH} className={"accordion-column"}>
                        {formatCurrency(netAssetsComparePlan1)}
                    </Col>
                    <Col md={CHART_TITLE_CELL_WIDTH} className={!isEmptyState ? "accordion-column" : ""}>
                        {comparePlan2SummaryResponse ? renderTableContent(
                                netAssetsComparePlan2, showDeltaBetweenPlans, differenceInNetAssets) :
                            <div style={{border: "noBorder !important"}}></div>}
                    </Col>
                </div>
            </Row>

            <Row className={"row-styling-accordion"}>
                <div className={"row-container"}>
                    <Col className={"accordion-header"} width={TITLE_CELL_WIDTH}
                         style={{paddingLeft: 0, borderLeft: "15px solid #F1B040"}}>
                        Goals
                    </Col>
                    <Col md={CHART_TITLE_CELL_WIDTH} className={"accordion-column"}>
                        {formatCurrency(totalGoalsComparePlan1)}
                    </Col>
                    <Col md={CHART_TITLE_CELL_WIDTH} className={!isEmptyState ? "accordion-column" : ""}>
                        {comparePlan2SummaryResponse ? renderTableContent(
                                totalGoalsComparePlan2, showDeltaBetweenPlans, differenceInTotalGoals) :
                            <div style={{border: "noBorder !important"}}></div>}
                    </Col>
                </div>
            </Row>
            <Row className={"row-styling-accordion"}>
                <div className={"row-container"}>
                    <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}
                         className={getExcessAssetsClassName(excessAssetsComparePlan1, excessAssetsComparePlan2)}>
                        {(excessAssetsComparePlan1 < 0 && excessAssetsComparePlan2 <= 0) ? 'Asset Shortfall' : 'Excess Assets'}
                    </Col>
                    <Col md={CHART_TITLE_CELL_WIDTH}
                         className={getClassName(excessAssetsComparePlan1)}>
                        {formatCurrency(excessAssetsComparePlan1)}
                    </Col>
                    <Col md={CHART_TITLE_CELL_WIDTH} className={!isEmptyState ? getClassName(excessAssetsComparePlan2) : ""}>
                        {comparePlan2SummaryResponse ? renderTableContent(
                                excessAssetsComparePlan2, showDeltaBetweenPlans, differenceInExcessAssets) :
                            <div style={{border: "noBorder !important"}}></div>}
                    </Col>
                </div>
            </Row>
            <Row className={"row-styling"}>
                <div className="row-container">
                    <Col width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
                        <div className="compare-plans-sub-header">Net Worth Over Time</div>
                        <div className='subhead'>Expected excess at the end of the planning period</div>
                    </Col>
                    <span className="divider"></span>
                    <Col md={CHART_TITLE_CELL_WIDTH}>
                        <NetWorthOverTimeRow
                            comparePlan1PlanSummaryResponse={comparePlan1SummaryResponse!}
                            comparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                            comparePlan1GraphOptions={comparePlan1GraphOptions}
                            comparePlan2GraphOptions={comparePlan2GraphOptions}
                            differenceInFutureValueOfExcessAssets={(futureValueOfExcessAssetsComparePlan2 - futureValueOfExcessAssetsComparePlan1)}
                            isComparePlan2={false}
                            showPillText={showDeltaBetweenPlans}
                        />
                    </Col>
                    <span className="divider"></span>

                    <Col md={CHART_TITLE_CELL_WIDTH}>
                        <NetWorthOverTimeRow
                            comparePlan1PlanSummaryResponse={comparePlan1SummaryResponse!}
                            comparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                            comparePlan1GraphOptions={comparePlan1GraphOptions}
                            comparePlan2GraphOptions={comparePlan2GraphOptions}
                            differenceInFutureValueOfExcessAssets={(futureValueOfExcessAssetsComparePlan2 - futureValueOfExcessAssetsComparePlan1)}
                            isComparePlan2={true}
                            showPillText={showDeltaBetweenPlans}
                        />
                    </Col>
                </div>
            </Row>
            <Row className={"row-styling"}>
                <div className="row-container">
                    <Col className={"col-visual-borders"} width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
                        <div className="compare-plans-sub-header">Proposed Asset Allocation</div>
                        <div className='subhead'>Total Portfolio</div>
                    </Col>
                    <span className="divider"></span>
                    <Col className={"col-visual-borders"} md={CHART_TITLE_CELL_WIDTH}>
                        <ComparePlansRiskDonut
                            comparePlan1PlanSummaryResponse={comparePlan1SummaryResponse!}
                            comparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                            isComparePlan2={false}
                        />
                    </Col>
                    <span className="divider"></span>
                    <Col className={!isEmptyState ? "col-visual-borders" : ""} md={CHART_TITLE_CELL_WIDTH}>
                        <ComparePlansRiskDonut
                            comparePlan1PlanSummaryResponse={comparePlan1SummaryResponse!}
                            comparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                            isComparePlan2={true}
                            differenceInTotalRiskPercent={differenceInTotalRiskPercent}
                            showPillText={showDeltaBetweenPlans}
                        />
                    </Col>
                </div>
            </Row>
            <Row className={"row-styling"}>
                <div className={"row-container"}>
                    <Col className={"col-visual-borders"} width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
                        <div className="compare-plans-sub-header" style={{marginTop: 15}}>Portfolio Reserve Target
                            Length
                        </div>
                    </Col>
                    <span className="divider"></span>
                    <Col className={"col-visual-borders"} md={CHART_TITLE_CELL_WIDTH}>
                        <ReserveTargetLengthRow
                            comparePlan1PortfolioReserveTargetLength={comparePlan1PortfolioReserveTargetLength}
                            comparePlan2PortfolioReserveTargetLength={comparePlan2PortfolioReserveTargetLength}
                            comparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                            isComparePlan2={false}
                        />
                    </Col>
                    <span className="divider"></span>
                    <Col className={!isEmptyState ? "col-visual-borders" : ""} md={CHART_TITLE_CELL_WIDTH}>
                        <ReserveTargetLengthRow
                            comparePlan1PortfolioReserveTargetLength={comparePlan1PortfolioReserveTargetLength}
                            comparePlan2PortfolioReserveTargetLength={comparePlan2PortfolioReserveTargetLength}
                            comparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                            isComparePlan2={true}
                            showPillText={showDeltaBetweenPlans}
                            differenceInPortfolioReserveTargetLength={differenceInPortfolioReserveTargetLength}
                        />
                    </Col>
                </div>
            </Row>
            <Row className={"row-styling"}>
                <div className={"row-container"}>
                    <Col className={"col-visual-borders"} width={TITLE_CELL_WIDTH} style={{paddingLeft: 0}}>
                        <div className="compare-plans-sub-header" style={{marginTop: 15}}>Estimated Estate Tax
                        </div>
                    </Col>
                    <span className="divider"></span>
                    <Col className={"col-visual-borders"} md={CHART_TITLE_CELL_WIDTH}>
                        <EstimatedEstateTaxRow
                            ComparePlan1EstimatedEstateTax={comparePlan1EstateSummary.estimatedEstateTax}
                            ComparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                            ComparePlan2EstimatedEstateTax={comparePlan2EstateSummary.estimatedEstateTax}
                            DifferenceInEstimatedEstateTax={differenceInEstimatedEstateTax}
                            isComparePlan2={false}
                        />
                    </Col>
                    <span className="divider"></span>
                    <Col className={!isEmptyState ? "col-visual-borders" : ""} md={CHART_TITLE_CELL_WIDTH}>
                        <EstimatedEstateTaxRow
                            ComparePlan1EstimatedEstateTax={comparePlan1EstateSummary.estimatedEstateTax}
                            ComparePlan2PlanSummaryResponse={comparePlan2SummaryResponse!}
                            ComparePlan2EstimatedEstateTax={comparePlan2EstateSummary.estimatedEstateTax}
                            DifferenceInEstimatedEstateTax={differenceInEstimatedEstateTax}
                            isComparePlan2={true}
                            showPillText={showDeltaBetweenPlans}
                        />
                    </Col>
                </div>
            </Row>
        </main>
    )
}

export default ComparePlansContent;
export const TITLE_CELL_WIDTH = 374;
export const CHART_CELL_WIDTH = 370;
export const CHART_TITLE_CELL_WIDTH = 4;