import React from "react";
import {useAppSelector} from "../../store/hooks";

import FutureEstateValueCard from "./FutureEstateValueCard";
import SummaryCard from "./SummaryCard";
import ImpactCard from "./ImpactCard";
import {selectEstimatedImpactSummary} from "../WealthPlanOptimizerSlice";
import {StateInputDto} from "../WealthPOTypes";
import StateOfResidencyOverview from "./StateOfResidencyOverview";

interface StrategySummarySectionProps {
    listOfStates: StateInputDto[];
    isInReportPreview: boolean;
}

const StrategySummarySection: React.FC<StrategySummarySectionProps> = ({
                                                                           listOfStates,
                                                                           isInReportPreview
                                                                       }: StrategySummarySectionProps) => {
    const estimatedImpactSummary = useAppSelector(selectEstimatedImpactSummary);

    return (
        <section>
            <SummaryCard/>
            <section className={"estimated-impact-summary-section"}>
                <FutureEstateValueCard/>
                <div className="summary-container">
                    <div className="impact-section">
                        <ImpactCard impactHeaderText={"Amount to Beneficiaries"}
                                    impactValue={
                                        estimatedImpactSummary.onlyStateOfResidencyStrategySelected
                                            ? null
                                            : estimatedImpactSummary.totalImpactToAmountToBeneficiaries
                                    }
                                    impactTypeClass={"amount-to-beneficiaries"}/>

                        <ImpactCard impactHeaderText={"Est. Estate Tax"}
                                    impactValue={estimatedImpactSummary.totalImpactToEstEstateTax}
                                    impactTypeClass={"estate-tax-savings"}/>
                    </div>
                    <StateOfResidencyOverview isInReportPreview={isInReportPreview}
                                              listOfStates={listOfStates}
                    />
                </div>
            </section>
        </section>
    );
}

export default StrategySummarySection;