import React from "react";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {Dropdown, DropdownItem} from "../../components";
import {Beneficiaries, Trustee} from "../EstateFlowchartTypes";

type AddTrustButtonDropdownProps = {
    className: string,
    updateBeneficiaryInformation: Function,
    beneficiariesInformation: Beneficiaries,
}

export const AddDistributionButtonDropdown: React.FC<AddTrustButtonDropdownProps> = ({
                                                                                         className,
                                                                                         updateBeneficiaryInformation,
                                                                                         beneficiariesInformation
                                                                                     }) => {
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const dropdownItemArray = () => {
        return [
            {
                itemText: "Marital Trust",
                value: "Marital Trust",
                onClick: () => {
                    if (!beneficiariesInformation.firstDeath) {
                        updateBeneficiaryInformation(
                            {
                                firstDeath: {
                                    maritalTrusts: [{
                                        trustName: "",
                                        tags: "",
                                        trustees: [],
                                        successors: [],
                                        beneficiary: [] as Trustee[],
                                        funding: "",
                                        distributions: "",
                                        termination: "",
                                        powersOfAppointment: []
                                    }]
                                }
                            } as Beneficiaries)
                    } else if (beneficiariesInformation.firstDeath.maritalTrusts) {
                        let trusts = [...beneficiariesInformation.firstDeath.maritalTrusts];
                        trusts.push({
                            trustName: "",
                            tags: "",
                            trustees: [],
                            successors: [],
                            beneficiary: [] as Trustee[],
                            funding: "",
                            distributions: "",
                            termination: "",
                            powersOfAppointment: [],
                            beneficiaryId: trusts[0].beneficiaryId
                        })
                        updateBeneficiaryInformation(
                            {
                                firstDeath: {
                                    maritalTrusts: trusts
                                }
                            } as Beneficiaries)
                    }
                },
                disabled: false
            },
        ]
    };

    return <Dropdown
        className={className}
        alignRight={false}
        buttonIcon="left"
        buttonKind="primary"
        dropdownKind="menu"
        defaultText="Add Distribution"
        iconNameOpen="add"
        iconNameClose="add"
        panelWidth={245}
        rounded
        disabled={isProfileWithProposalsOrArchived}
    >
        {dropdownItemArray().map((item: any, i: number) =>
            <DropdownItem key={i}
                          itemText={item.itemText}
                          value={item.value}
                          onClick={item.onClick}
                          disabled={item.disabled}
            />)}
    </Dropdown>;

};