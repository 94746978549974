import { FC } from 'react';
import ModalWrapper from "../Modal/ModalWrapper/ModalWrapper";

export type DiscardModalProps = {
    itemType?: string;
    isOpen: boolean;
    onClickKeepEditing: () => void,
    onClickDiscardChanges: () => void,
    isEditing: boolean
}

/**
 * DiscardModal component.
 * 
 * This component renders a modal dialog that prompts the user to confirm if they want to discard changes made to a form.
 * 
 * @component
 * @param itemType - The name of the form being edited. (deprecated)
 * @param isOpen - Flag indicating if the modal is open.
 * @param onClickKeepEditing - Callback function when the "Keep Editing" button is clicked.
 * @param onClickDiscardChanges - Callback function when the "Discard Changes" button is clicked.
 * @param isEditing - Flag indicating if the item is currently being edited or created for the first time.
 * @returns {JSX.Element} The rendered DiscardModal component.
 */
const DiscardModal: FC<DiscardModalProps> = ({ itemType, isOpen = false, onClickKeepEditing, onClickDiscardChanges, isEditing = false }): JSX.Element => {
    if (itemType) {
        return (
            <ModalWrapper
                id="discard-changes-modal"
                isOpen={isOpen}
                headerText={isEditing ? `Discard changes to this ${itemType}?` : `Discard this ${itemType}?`}
                buttons={[
                    {
                        text: 'Keep Editing',
                        onClick: onClickKeepEditing,
                    },
                    {
                        text: 'Discard Changes',
                        onClick: onClickDiscardChanges,
                        destructive: true,
                        primary: true,
                    }
                ]}
            >
                <div className="font-md">Any data entered for this {itemType} will not be saved.</div>
            </ModalWrapper>
        )
    } else {
        return (
            <ModalWrapper
                id="discard-changes-modal"
                isOpen={isOpen}
                headerText={"Discard changes?"}
                buttons={[
                    {
                        text: 'Keep Editing',
                        onClick: onClickKeepEditing,
                    },
                    {
                        text: 'Discard Changes',
                        onClick: onClickDiscardChanges,
                        destructive: true,
                        primary: true,
                    }
                ]}
            >
                <div className="font-md">Any data entered will not be saved.</div>
            </ModalWrapper>
        )
    }

}

export default DiscardModal;
