import {DocumentDetails, LinkProps, SubMenuProps,} from "../../models/routeData/RouteParamTypes";
import {ProfileResponse, ProfileType} from "../models/ProfileResponse";
import {AdvisorDocumentMenus} from "../../Resources/models/AdviceLibrary";
import {ReleaseTogglesType} from "../../ReleaseToggles/ReleaseToggles";
import {ClientMeetingDocumentsResponse} from "../../Resources/models/ClientDocuments";


function getGoalsLinks(id: string, releaseToggles: ReleaseTogglesType) {
    const goalsSummaryLink = {
        id: "GoalsSummary",
        path: `/Profile/${id}/ClientProfile/Goals/Summary`,
        name: "Goals Summary",
        enabled: true,
    };
    const goalsPrioritizationLink = {
        id: "GoalsPrioritization",
        path: `/Profile/${id}/ClientProfile/Goals/Prioritization`,
        name: "Prioritize Goals",
        enabled: true,

        hidden: !releaseToggles.enableGoalsPrioritization
    };
    const goalsFundingOverTimeLink = {
        id: "GoalsFundingOverTime",
        path: `/Profile/${id}/ClientProfile/Goals/Funding`,
        name: "Goal Funding Over Time",
        enabled: true,

        hidden: !releaseToggles.enableGoalsFunding
    };

    return {
        id: "Goals",
        name: "Goals",
        enabled: true,
        children: [goalsSummaryLink, goalsPrioritizationLink, goalsFundingOverTimeLink],
    }
}

function getPortfolioReserveLinks(id: string, profile: ProfileResponse) {
    const isPortfolioReserveSet = profile.portfolioReserveTargetLength != undefined

    const prLink = isPortfolioReserveSet ? {
        id: "Edit Portfolio Reserve",
        name: "Edit Portfolio Reserve",
        path: `/Profile/${id}/ClientProfile/PortfolioReserve/SetPortfolioReserve/EditPortfolioReserve`,
        enabled: true,
    } : {
        id: "Portfolio Reserve",
        name: "Set Portfolio Reserve",
        path: `/Profile/${id}/ClientProfile/PortfolioReserve/SetPortfolioReserve/EditPortfolioReserve`,
        enabled: true,
    };

    const reviewPRLink = isPortfolioReserveSet ? {
        id: "SetPortfolioReserve",
        path: `/Profile/${id}/ClientProfile/PortfolioReserve/ReviewPortfolioReserve`,
        name: "Review Portfolio Reserve",
        enabled: true
    } : {
        id: "SetPortfolioReserve",
        path: `/Profile/${id}/ClientProfile/PortfolioReserve/SetPortfolioReserve`,
        name: "Review Portfolio Reserve",
        enabled: true
    }
    const monitorPRLink = {
        id: "SetPortfolioReserve",
        path: `/Profile/${id}/ClientProfile/PortfolioReserve/MonitorPortfolioReserve`,
        name: "Monitor Portfolio Reserve",
        enabled: true,
        hidden: !profile.activePortfolioReserve
    }

    return {
        id: "Portfolio Reserve",
        name: "Portfolio Reserve",
        enabled: true,
        children: [prLink, reviewPRLink, monitorPRLink],
    }
}

function getWealthPlanningLinks(profileId: string, releaseToggles: ReleaseTogglesType) {
    const assetTitlingHiddenFlag = !releaseToggles.enableAssetTitling;
    const wealthPlanOptimizerHiddenFlag = !releaseToggles.enableWealthPlanOptimizer;
    const wealthTransferHiddenFlag = !releaseToggles.enableHolisticAdvice;
    const estateFlowchartHiddenFlag = !releaseToggles.enableEstateFlowchart;
    const assetTitlingLink = {
        id: "AssetTitling",
        name: "Asset Titling",
        hidden: assetTitlingHiddenFlag,
        enabled: true,
        path: `/Profile/${profileId}/ClientProfile/AssetTitling`
    };
    const wealthTransferLink = {
        id: "WealthTransfer",
        name: "Wealth Transfer",
        hidden: wealthTransferHiddenFlag,
        enabled: true,
        path: `/Profile/${profileId}/ClientProfile/WealthTransferPlanning`
    };
    const estateFlowchart = {
        id: "EstateFlowchart",
        name: "Estate Flowchart",
        hidden: estateFlowchartHiddenFlag,
        enabled: true,
        path:`/Profile/${profileId}/ClientProfile/EstateFlowchart`
    };
    const wealthPlanOptimizerLink = {
        id: "WealthPlanOptimizer",
        name: "Wealth Plan Optimizer",
        hidden: wealthPlanOptimizerHiddenFlag,
        enabled: true,
        path: `/Profile/${profileId}/ClientProfile/WealthPlanOptimizer/Summary`
    };
    return {
        id: "WealthPlanning",
        name: "Wealth Planning",
        enabled: true,
        children: [assetTitlingLink, wealthTransferLink, estateFlowchart, wealthPlanOptimizerLink],
    }
}

function getPlanOverviewLinks(profileId: string, releaseToggles: ReleaseTogglesType) {
    const disableCompareScenarios = !releaseToggles.enableCompareScenarios
    const planSummaryLink = {
        id: "PlanSummary",
        path: `/Profile/${profileId}/ClientProfile/PlanSummary`,
        name: "Plan Summary",
        enabled: true,
    }
    const comparePlansLink = {
        id: "ComparePlans",
        name: "Compare Plans",
        path: `/Profile/${profileId}/ClientProfile/ComparePlans`,
        enabled: true,
    }

    if (disableCompareScenarios) {
        return {
            id: "PlanSummary",
            name: "Plan Summary",
            path: `/Profile/${profileId}/ClientProfile/PlanSummary`,
            enabled: true,
        };

    } else {
        return {
            id: "PlanOverview",
            name: "Plan Overview",
            enabled: true,
            children: [planSummaryLink, comparePlansLink],
        };
    }
}

export function getAdvisorDocumentsPracticeDocumentsSubMenu(advisorDocumentsMenu: AdvisorDocumentMenus) {
    const menuLink: SubMenuProps[] | undefined = [];
    let children1: DocumentDetails[] = [];
    let children2: DocumentDetails[] = [];
    let children3: DocumentDetails[] = [];
    let subMenuItem1 = {
        id: "Practice Documents",
        title: "Practice Documents",
        children: children1
    }

    let subMenuItem2 = {
        id: "",
        title: "",
        children: children2
    }

    let subMenuItem3 = {
        id: "",
        title: "",
        children: children3
    }

    advisorDocumentsMenu.advisorDocumentSubMenus.forEach(submenu => {
        if(!(submenu.subMenuLabel === "Financial Essentials" || submenu.subMenuLabel === "GDWM Practice"))
        {
            submenu.subMenuItems.forEach(item => {
                children1.push({
                    label: item.documentLabel,
                    subMenuLabel: submenu.subMenuLabel,
                    link: item.documentLabel
                });
            })

        }
        else if((submenu.subMenuLabel === "GDWM Practice"))
        {
            subMenuItem2 = {
                id: submenu.subMenuLabel.replaceAll(' ', ''),
                title: submenu.subMenuLabel,
                children: children2
            }
            submenu.subMenuItems.forEach(item => {
                children2.push({
                    label: item.documentLabel,
                    subMenuLabel: submenu.subMenuLabel,
                    link: item.documentLabel
                });
            })

        }
        else if((submenu.subMenuLabel === "Financial Essentials"))
        {
            subMenuItem3 = {
                id: submenu.subMenuLabel.replaceAll(' ', ''),
                title: submenu.subMenuLabel,
                children: children3
            }
            submenu.subMenuItems.forEach(item => {
                children3.push({
                    label: item.documentLabel,
                    subMenuLabel: submenu.subMenuLabel,
                    link: item.documentLabel
                });
            })

        }

    })

    subMenuItem1.children = sortDocumentsBasedOnLabelNames(children1)
    subMenuItem2.children = sortDocumentsBasedOnLabelNames(children2)
    subMenuItem3.children = sortDocumentsBasedOnLabelNames(children3)
    menuLink.push(subMenuItem1);
    menuLink.push(subMenuItem2);
    menuLink.push(subMenuItem3);
    return menuLink;
}


export function getAdvisorDocumentsAdviceLibrarySubMenu(advisorDocumentsMenu: AdvisorDocumentMenus) {
    const menuLink: SubMenuProps[] | undefined = [];
    advisorDocumentsMenu.advisorDocumentSubMenus.forEach(submenu => {

        if (submenu.subMenuLabel !== 'GRAT' && submenu.subMenuLabel !== 'Gift to Grantor Trust' && submenu.subMenuLabel !== 'Annual Gifting' && submenu.subMenuLabel !== 'Change of Residency') {
            let children: DocumentDetails[] = [];
            let subMenuItem = {
                id: submenu.subMenuLabel.replaceAll(' ', ''),
                title: submenu.subMenuLabel,
                children
            }

            submenu.subMenuItems.forEach(item => {
                children.push({
                    label: item.documentLabel,
                    subMenuLabel: submenu.subMenuLabel,
                    link: item.documentLabel
                });
            })
            subMenuItem.children = sortDocumentsBasedOnLabelNames(children)
            menuLink.push(subMenuItem);
        }
    })
    return menuLink;
}

function sortDocumentsBasedOnLabelNames(children: DocumentDetails[])
{
    return children.sort((ele1, ele2) => (ele1.label.localeCompare(ele2.label)));
}

export function getClientMeetingDocumentsSubMenu(clientMeetingDocumentSubMenus: ClientMeetingDocumentsResponse) {
    const menuLink: SubMenuProps[] = [];
    let children: DocumentDetails[] = [];

    let subMenuItem: SubMenuProps = {
        id: clientMeetingDocumentSubMenus.folderItem ? clientMeetingDocumentSubMenus.folderItem?.name : "",
        title: clientMeetingDocumentSubMenus.folderItem ? clientMeetingDocumentSubMenus.folderItem?.name : undefined,
        children,
        subMenuFolders: []
    }

    clientMeetingDocumentSubMenus.documentItems.forEach(item => {
        children.push({
            label: item.name,
            link: item.id
        });
    })

    subMenuItem.children = children;
    if (clientMeetingDocumentSubMenus.folderItems && clientMeetingDocumentSubMenus.folderItems.length > 0) {

        clientMeetingDocumentSubMenus.folderItems.forEach(folderItem => {
            const clientMeetingDocumentSubMenusList: ClientMeetingDocumentsResponse = {
                documentItems: folderItem.driveItems,
                folderItems: folderItem.folderItems,
                folderItem: folderItem
            }
            let subMenuLinkItemArray = getClientMeetingDocumentsSubMenu(clientMeetingDocumentSubMenusList);
            subMenuItem.subMenuFolders?.push(subMenuLinkItemArray[0]);
        })
    }
    menuLink.push(subMenuItem);
    return menuLink;
}

export const links = (
    id: string,
    profile: ProfileResponse,
    adviceLibraryMenu: AdvisorDocumentMenus,
    practiceDocumentsSubMenus: AdvisorDocumentMenus,
    clientDocumentsSubMenus: ClientMeetingDocumentsResponse,
    myDocumentsSubMenus: ClientMeetingDocumentsResponse,
    releaseToggles: ReleaseTogglesType,
): Array<LinkProps> => {


    return [
        {
            id: "profileSettings",
            name: profile.type === ProfileType.PROPOSAL ? "Proposal Settings" : "Profile Settings",
            enabled: true,
            path: profile.type === ProfileType.PROPOSAL ?
                `/Profile/${id}/ClientProfile/ProposalSettings/${profile.profileIdForProposal}` :
                `/Profile/${id}/ClientProfile/ProfileSettings`
        },
        {
            id: "agenda",
            name: "Agenda",
            enabled: true,
            path: `/Profile/${id}/ClientProfile/Agenda`
        },
        {
            id: "familyTree",
            name: "Family Tree",
            enabled: true,
            path: `/Profile/${id}/ClientProfile/FamilyTree`,
        },
        {
            id: "Assets",
            name: "Assets",
            enabled: true,
            children: [
                {
                    id: "AssetSummary",
                    path: `/Profile/${id}/ClientProfile/AssetSummary`,
                    name: "Asset Summary",
                    enabled: true,
                },
                {
                    id: "CurrentNetWorth",
                    name: "Current Net Worth",
                    path: `/Profile/${id}/ClientProfile/CurrentNetWorth`,
                    enabled: true,
                    hidden: !releaseToggles.enableCurrentNetWorth
                },
            ],
        },
        getGoalsLinks(id, releaseToggles),
        getPortfolioReserveLinks(id, profile),
        getPlanOverviewLinks(id, releaseToggles),
        {
            id: "AssetReliance",
            path: `/Profile/${id}/ClientProfile/AssetReliance`,
            name: "Asset Reliance",
            enabled: true,
        },
        {
            id: "NetWorthOverTime",
            path: `/Profile/${id}/ClientProfile/NetWorthOverTime`,
            name: "Net Worth Over Time",
            enabled: true,
            hidden: !releaseToggles.enabledNetWorthOverTime
        },
        {
            id: "AssetAllocation",
            name: "Asset Allocation",
            enabled: true,
            children: [
                {
                    id: "CurrentVsProposed",
                    path: `/Profile/${id}/ClientProfile/AssetAllocation/CurrentVsProposed`,
                    name: "Current vs. Proposed",
                    enabled: true,
                },
            ],
        },
        getWealthPlanningLinks(id, releaseToggles),
        {
            id: "Reporting",
            path: `/Profile/${id}/ClientProfile/CreateReport`,
            name: "Reporting",
            enabled: true,
        },
        {
            id: "Resources",
            name: "Resources",
            enabled: true,
            children: [
                {
                    id: "GDWMIntro",
                    name: "GDWM Intro",
                    path: `/Profile/${id}/ClientProfile/GDWMIntro`,
                    enabled: true,
                    hidden: true,
                },
                {
                    id: "RiskManagementIntro",
                    name: "Risk Management Intro",
                    path: `/Profile/${id}/ClientProfile/RiskManagementIntro`,
                    enabled: false,
                    hidden: true,
                },
                {
                    id: "AdviceLibrary",
                    name: "Advice Library",
                    path: `/Profile/${id}/ClientProfile/Resources/AdviceLibrary`,
                    enabled: true,
                    subMenuType: "right",
                    subMenuTitle: "Advice Library",
                    subMenuOptions: getAdvisorDocumentsAdviceLibrarySubMenu(adviceLibraryMenu),
                },
                {
                    id: "PracticeDocuments",
                    name: "Practice Documents",
                    path: `/Profile/${id}/ClientProfile/Resources/PracticeDocuments`,
                    enabled: true,
                    subMenuType: "right",
                    subMenuTitle: "Practice Documents",
                    subMenuOptions: getAdvisorDocumentsPracticeDocumentsSubMenu(practiceDocumentsSubMenus),
                },
                {
                    id: "ClientDocuments",
                    name: "Client Documents",
                    path: `/Profile/${id}/ClientProfile/Resources/ClientDocuments`,
                    enabled: true,
                    hidden: !releaseToggles.enableClientDocument,
                    subMenuType: "right",
                    subMenuTitle: "Client Documents",
                    subMenuOptions: (clientDocumentsSubMenus.documentItems.length > 0 || clientDocumentsSubMenus.folderItems.length > 0) ? getClientMeetingDocumentsSubMenu(clientDocumentsSubMenus) : [],
                },
                {
                    id: "MyDocuments",
                    name: "My Documents",
                    path: `/Profile/${id}/ClientProfile/Resources/MyDocuments`,
                    enabled: true,
                    hidden: !releaseToggles.enableClientDocument,
                    subMenuType: "right",
                    subMenuTitle: "My Documents",
                    subMenuOptions: (myDocumentsSubMenus.documentItems.length > 0 || myDocumentsSubMenus.folderItems.length > 0) ? getClientMeetingDocumentsSubMenu(myDocumentsSubMenus) : [],
                },
                {
                    id: "ExternalDocuments",
                    name: "External Documents",
                    path: `/Profile/${id}/ClientProfile/ExternalDocuments`,
                    enabled: true,
                    hidden: true,
                },
                {
                    id: "QuickSlides",
                    name: "Quick Slides",
                    path: `/Profile/${id}/ClientProfile/QuickSlides`,
                    enabled: false,
                    hidden: true,
                },
                {
                    id: "TrainingMode",
                    name: "Training Mode",
                    path: `/Profile/${id}/ClientProfile/TrainingMode`,
                    enabled: false,
                    hidden: true,
                },
            ],
        },
    ];
};
