export const enum FailedAPICall {
    ESTIMATED_IMPACT = "Estimated Impact",
    REFERENCE_DATA = "Reference Data",
    PAYOUT_PERCENTAGES = "Payout Percentages",
}

export const enum StrategyType {
    STATE_OF_RESIDENCY = "STATE_OF_RESIDENCY",
    ANNUAL_GIFTING = "ANNUAL_GIFTING",
    GIFT_TO_GRANTOR_TRUST = "GTGT",
    TWO_YEAR_GRAT = "TWO_YEAR_GRAT",
    FIVE_YEAR_GRAT = "FIVE_YEAR_GRAT",
    ROLLING_GRAT = "ROLLING_GRAT"
}

export type RateDto = {
    id: string,
    description: string,
    value: number,
}

export type GrowthRates = {
    rateDtos: RateDto[],
};

export type HurdleRateDTO = {
    hurdleRate: number,
    hurdleRateMonth: string,
    hurdleRateYear: string
};

export type GRATRates = {
    growthRates: GrowthRates,
    hurdleRateDTO: HurdleRateDTO
}

export type StrategyTimeFrame = {
    strategyId: string | undefined,
    strategyType: StrategyType,
    enabled: boolean;
    contributionAmount: number,
};

export type EstimatedImpact = {
    amountToBeneficiaries: number | undefined,
    impactToEstEstateTax: number | undefined,
    strategyPayoutSchedule: StrategyPayoutScheduleItem[]
};

export type FutureEstateValue = {
    totalAmountToBeneficiaries: number,
    totalEstEstateTax: number
}

export const initialFutureEstateValue: FutureEstateValue = {
    totalAmountToBeneficiaries: 0,
    totalEstEstateTax: 0
}

export const initialEstimatedImpact: EstimatedImpact = {
    amountToBeneficiaries: undefined,
    impactToEstEstateTax: undefined,
    strategyPayoutSchedule: []
};

// State of Residency Strategy Types
export type StateInputDto = {
    id: string,
    name: string,
    forecastedEstateTaxRate: number
};

export type StateEstateTransferRates = {
    stateInputsDTOs: StateInputDto[],
};

export type StateOfResidencyStrategy = {
    id: string | undefined,
    description: string,
    selectedStateOfResidency: string,
    estimatedImpact: EstimatedImpact;
    enabled: boolean;
}

export const initialStateOfResidencyStrategy: StateOfResidencyStrategy = {
    id: undefined,
    description: "State of Residency",
    selectedStateOfResidency: "",
    estimatedImpact: initialEstimatedImpact,
    enabled: true
}

// Annual Gifting Strategy Types
export type AnnualGiftingStrategy = StrategyTimeFrame & {
    sequenceNumber?: number,
    description: string,
    yearsOfFlow: number,
    yearsUntilFlow: number,
    estimatedImpact: EstimatedImpact
}

export const initialAnnualGiftingStrategy: AnnualGiftingStrategy = {
    strategyId: undefined,
    strategyType: StrategyType.ANNUAL_GIFTING,
    description: "Annual Gifting",
    contributionAmount: 0,
    yearsUntilFlow: 0,
    yearsOfFlow: 1,
    estimatedImpact: initialEstimatedImpact,
    enabled: true
}

// Gift to Grantor Trust Strategy Types
export type GiftToGrantorTrustStrategy = StrategyTimeFrame & {
    sequenceNumber?: number,
    description: string,
    yearsOfGrantorStatus: number | undefined
    estimatedImpact: EstimatedImpact
}

export const initialGiftToGrantorTrustStrategy: GiftToGrantorTrustStrategy = {
    strategyId: undefined,
    strategyType: StrategyType.GIFT_TO_GRANTOR_TRUST,
    description: "Gift to Grantor Trust",
    contributionAmount: 0,
    yearsOfGrantorStatus: undefined,
    estimatedImpact: initialEstimatedImpact,
    enabled: true
}

// GRAT Strategy Types
export type GRATStrategy = StrategyTimeFrame & {
    sequenceNumber?: number,
    description: string,
    increaseInPayment: number,
    annuityRate: number | undefined,
    yearsOfFlow: number,
    estimatedImpact: EstimatedImpact
}

export const initialTwoYearGRATStrategy: GRATStrategy = {
    strategyId: undefined,
    strategyType: StrategyType.TWO_YEAR_GRAT,
    description: "2-Year GRAT",
    contributionAmount: 0,
    increaseInPayment: 20,
    annuityRate: undefined,
    yearsOfFlow: 2,
    estimatedImpact: initialEstimatedImpact,
    enabled: true
}

export const initialFiveYearGRATStrategy: GRATStrategy = {
    strategyId: undefined,
    strategyType: StrategyType.FIVE_YEAR_GRAT,
    description: "5-Year GRAT",
    contributionAmount: 0,
    increaseInPayment: 20,
    annuityRate: undefined,
    yearsOfFlow: 5,
    estimatedImpact: initialEstimatedImpact,
    enabled: true
}

export const initialRollingGRATStrategy: GRATStrategy = {
    strategyId: undefined,
    strategyType: StrategyType.ROLLING_GRAT,
    description: "Rolling GRAT",
    contributionAmount: 0,
    increaseInPayment: 20,
    annuityRate: undefined,
    yearsOfFlow: 3,
    estimatedImpact: initialEstimatedImpact,
    enabled: true
}

export type PayoutPercentagesRequestDTO = {
    contributionAmount: number,
    increaseInPayment: number,
    annuityRate: number,
    strategyType: StrategyType
}

export type PayoutPercentagesResponseDTO = {
    payoutPercentages: number[]
}

export const initialPayoutPercentagesTwo: PayoutPercentagesResponseDTO = {
    payoutPercentages: [0.0, 0.0]
}
export const initialPayoutPercentagesFive: PayoutPercentagesResponseDTO = {
    payoutPercentages: [0.0, 0.0, 0.0, 0.0, 0.0]
}

export type GRATEstimatedImpactRequestDTO = StrategyTimeFrame & PayoutPercentagesResponseDTO & {
    yearsOfFlow: number
}

export type StrategyPayoutScheduleItem = {
    index: number,
    contributionAmount: number,
    year1Annuity: number,
    year2Annuity: number,
    remainder: number
}

// WealthPO Summary Types
export type StrategyDetailsSectionInfo = {
    id: string | undefined,
    description: string,
    type: StrategyType,
    amountToBeneficiaries: number,
    impactToEstEstateTax: number,
    enabled: boolean;
}

export type StateOfResidencySectionInfo = StateOfResidencyStrategy & {
    effectiveRate: number | undefined
}

export const initialStateOfResidencySectionInfo: StateOfResidencySectionInfo = {
    id: undefined,
    description: "State of Residency",
    selectedStateOfResidency: "",
    estimatedImpact: initialEstimatedImpact,
    enabled: true,
    effectiveRate: undefined
}

export type EstimatedImpactSummary = {
    onlyStateOfResidencyStrategySelected: boolean;
    totalImpactToAmountToBeneficiaries: number | undefined,
    totalImpactToEstEstateTax: number | undefined,
}
export const initialEstimatedImpactSummary: EstimatedImpactSummary = {
    onlyStateOfResidencyStrategySelected: false,
    totalImpactToAmountToBeneficiaries: undefined,
    totalImpactToEstEstateTax: undefined,
}

export type WealthPOSummaryResponse = {
    stateOfResidencyStrategy: StateOfResidencyStrategy;
    annualGiftingStrategies: AnnualGiftingStrategy[];
    giftToGrantorTrustStrategies: GiftToGrantorTrustStrategy[];
    gratStrategies: GRATStrategy[]
    estimatedImpactSummary: EstimatedImpactSummary;
    futureEstateValueWithStrategies: FutureEstateValue;
    futureEstateValueWithoutStrategies: FutureEstateValue;
}
export const initialWealthPOSummaryResponse: WealthPOSummaryResponse = {
    stateOfResidencyStrategy: initialStateOfResidencyStrategy,
    annualGiftingStrategies: [],
    giftToGrantorTrustStrategies: [],
    gratStrategies: [],
    estimatedImpactSummary: initialEstimatedImpactSummary,
    futureEstateValueWithStrategies: initialFutureEstateValue,
    futureEstateValueWithoutStrategies: initialFutureEstateValue
};