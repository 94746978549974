import React from "react";

export const StateOfResidencyAccordionHeader = () => {
    return (
        <div className={"state-of-residency-accordion-header"}
             data-testid={"state-of-residency-accordion-header"}>
            <span className={"span-1"}>Name</span>
            <span className={"span-2"}>State</span>
            <span className={"span-3 textalign-right"}>Effective Rate %</span>
        </div>
    )
};