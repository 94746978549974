import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import AlertBanner from "../../components/AlertBanner/AlertBanner";
import React, {useState} from "react";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import DiscardModal from "../../components/DiscardModal/DiscardModal";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import GenericErrorModal, {GenericErrorModalData} from "../../components/Modal/Error/GenericErrorModal";

export interface StrategyHeaderProps {
    header: string,
    handleSave: () => Promise<HandleSaveResponse>,
    handleCancel: () => boolean,
    refreshStrategySummary: () => void
}

export interface HandleSaveResponse {
    isSuccess: boolean,
    errors: SaveErrors
}

export interface SaveErrors {
    hasValidationError: boolean,
    hasCommunicationError: boolean,
    traceId: string
}

export const StrategyHeader: React.FC<StrategyHeaderProps> = ({
                                                                  header,
                                                                  handleSave,
                                                                  handleCancel,
                                                                  refreshStrategySummary
                                                              }) => {
    const {id} = useParams<RouteWithId>();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const history = useHistory();

    const [validationErrorOnSave, setValidationErrorOnSave] = useState<boolean>(false);
    const [communicationErrorModalData, setCommunicationErrorModalData] = useState<GenericErrorModalData>({
        isOpen: false,
        header: "",
        message: '',
        operationId: ''
    })
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

    const handleSaveButtonClick = async () => {
        const {isSuccess, errors} = await handleSave();

        if (isSuccess) {
            navigateToLandingPage();
        } else if (errors.hasValidationError) {
            setValidationErrorOnSave(true)
        } else {
            setCommunicationErrorModalData({
                isOpen: true,
                header: "Communication Failure",
                message: (
                    <>
                        <p>We have experienced a communication failure. If this error remains, please contact the help
                            desk.</p>
                    </>
                ),
                operationId: errors.traceId
            })
        }
    }

    const handleCancelButtonClick = () => {
        const containsChanges = handleCancel();

        if (containsChanges) {
            setShowCancelModal(true)
        } else {
            navigateToLandingPage();
        }
    }

    const navigateToLandingPage = () => {
        refreshStrategySummary()
        history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/Summary`);
    }

    const showCommunicationErrorModal = () => setCommunicationErrorModalData({
        ...communicationErrorModalData,
        isOpen: false
    });

    return (
        <>
            <DataEntryHeader title={header}
                             primaryButtonText={"SAVE"}
                             secondaryButtonText={"CANCEL"}
                             disablePrimaryButton={isProfileWithProposalsOrArchived}
                             onPrimaryButtonClick={handleSaveButtonClick}
                             onSecondaryButtonClick={handleCancelButtonClick}
            />

            <div className={"__alert-banner-container"}>
                <AlertBanner showAlert={validationErrorOnSave}
                             className="marginbottom-lg"
                             detail={
                                 <span><b>Required fields missing. </b>
                                 All required fields must be entered to save a strategy.
                                 </span>
                             }>
                </AlertBanner>
            </div>
            {communicationErrorModalData.isOpen &&
                <GenericErrorModal
                    errorModalData={communicationErrorModalData}
                    onRequestClose={showCommunicationErrorModal}
                    onClickButton={showCommunicationErrorModal}
                    buttonText="CLOSE"
                />
            }

            <DiscardModal
                isOpen={showCancelModal}
                onClickKeepEditing={() => setShowCancelModal(false)}
                onClickDiscardChanges={navigateToLandingPage}
                isEditing={true}
            />
        </>
    )
}