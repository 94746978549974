import React from "react";
import {formatPercentWithTwoDecimals} from "../../utils/format";

type StateOfResidencyAccordionHeaderProps = {
    description: string,
    isProfileSOR: boolean,
    stateOfResidency: string,
    effectiveRate: number | undefined,
    isInReportPreview: boolean,
    enabled: boolean
};

export function StateOfResidencyAccordionItem({
                                                  description,
                                                  isProfileSOR,
                                                  stateOfResidency,
                                                  effectiveRate,
                                                  enabled,
                                                  isInReportPreview
                                              }: Readonly<StateOfResidencyAccordionHeaderProps>) {
    let effectiveRateClassname;
    if (enabled) {
        effectiveRateClassname = isProfileSOR ? "span-3-enabled_profile textalign-right" : "span-3-enabled textalign-right"
    } else {
        effectiveRateClassname = isProfileSOR ? "span-3-disabled_profile textalign-right" : "span-3-disabled textalign-right"
    }

    const displayEffectiveRate = effectiveRate === undefined ? "––" : formatPercentWithTwoDecimals(effectiveRate)
    const disabledStyle = !enabled ? 'disabled' : ''

    return (
        <div>
            {
                <div
                    className={isInReportPreview ? "state-of-residency-accordion-item__report" : "state-of-residency-accordion-item__web"}>
                    <span className={`span-1 ${disabledStyle}`}>{description}</span>
                    <span className={`span-2 ${disabledStyle}`}>{stateOfResidency}</span>
                    <span
                        className={effectiveRateClassname}>
                        {displayEffectiveRate}
                    </span>
                </div>
            }
        </div>
    );
}