import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store/store";
import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import {emptyInvestorGroup, InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {AssetsSummary, emptyAssetsSummary} from "../../Assets/models/Assets";
import {emptyEstateSummary, EstateSummary} from "../../WealthTransfer/models/api";

export interface ComparePlansState {
    comparePlan1SummaryResponse: PlanSummaryResponse;
    comparePlan2SummaryResponse: PlanSummaryResponse | null;
    comparePlan1SelectedId: string;
    comparePlan2SelectedId: string | null;
    comparePlan1InvestorGroup: InvestorGroupType;
    comparePlan2InvestorGroup: InvestorGroupType;
    comparePlan2ClientAssets: AssetsSummary | undefined;
    comparePlan1PortfolioReserveTargetLength: number | undefined;
    comparePlan2PortfolioReserveTargetLengthForProposal: number | undefined;
    comparePlan1EstateSummary: EstateSummary;
    comparePlan2EstateSummary: EstateSummary;
    comparePlanId: string;
    showDifferenceBetweenPlans: boolean;
}

const emptyPlanSummaryResponse: PlanSummaryResponse = {
    totalNetValue: 0,
    totalAssetPurchaseValue: 0,
    goalsTotalPresentValue: 0,
    excessAssets: 0,
    totalGrossValue: 0,
    standaloneAccountsTotalPresentValue: 0,
    equityCompensationsTotalPresentValue: 0,
    personalAssetsTotalPresentValue: 0,
    generalInflowsTotalPresentValue: 0,
    socialSecurityTotalPresentValue: 0,
    investmentProgramTotalMarketValue: 0,
    totalLiabilitiesValue: 0,
    futureValueOfExcessAssets: 0,
    futureValueOfExcessAssetsByAge: [0],
    hasFutureAssetPurchase: false,
    inEstateLifeInsuranceFundingGoals: 0,
    proposedAllocation: {
        totalRiskAssetsPercent: 0,
        totalRiskControlAssetsPercent: 0,
    }
};
export const initialState: ComparePlansState = {
    comparePlan1SummaryResponse: emptyPlanSummaryResponse,
    comparePlan2SummaryResponse: null,
    comparePlan1SelectedId: "",
    comparePlan2SelectedId: null,
    comparePlan1InvestorGroup: emptyInvestorGroup,
    comparePlan2InvestorGroup: emptyInvestorGroup,
    comparePlan2ClientAssets: emptyAssetsSummary,
    comparePlan1PortfolioReserveTargetLength: undefined,
    comparePlan2PortfolioReserveTargetLengthForProposal: undefined,
    comparePlan1EstateSummary: emptyEstateSummary,
    comparePlan2EstateSummary: emptyEstateSummary,
    comparePlanId: "",
    showDifferenceBetweenPlans: false
};

export const comparePlansSlice = createSlice({
    name: "comparePlans",
    initialState,
    reducers: {
        setComparePlan1SummaryResponse: (state, action: PayloadAction<PlanSummaryResponse>) => {
            state.comparePlan1SummaryResponse = action.payload;
        },
        setComparePlan2SummaryResponse: (state, action: PayloadAction<PlanSummaryResponse | null>) => {
            state.comparePlan2SummaryResponse = action.payload;
        },
        setComparePlan1SelectedId: (state, action: PayloadAction<string>) => {
            state.comparePlan1SelectedId = action.payload;
        },
        setComparePlan2SelectedId: (state, action: PayloadAction<string | null>) => {
            state.comparePlan2SelectedId = action.payload;
        },
        setComparePlanId: (state, action: PayloadAction<string>) => {
            state.comparePlanId = action.payload;
        },
        setDifferenceBetweenPlans: (state, action: PayloadAction<boolean>) => {
            state.showDifferenceBetweenPlans = action.payload;
        },
        resetComparePlan1SelectedId: (state) => {
            state.comparePlan1SelectedId = "";
        },
        resetComparePlan2SelectedId: (state) => {
            state.comparePlan2SelectedId = null;
        },
        resetComparePlan1SummaryResponse: (state) => {
            state.comparePlan1SummaryResponse = emptyPlanSummaryResponse;
        },
        resetComparePlan2SummaryResponse: (state) => {
            state.comparePlan2SummaryResponse = null;
            state.showDifferenceBetweenPlans = false;
        },

    },
});

export const {
    setComparePlan1SummaryResponse,
    setComparePlan2SummaryResponse,
    setComparePlan1SelectedId,
    setComparePlan2SelectedId,
    setComparePlanId,
    setDifferenceBetweenPlans,
    resetComparePlan2SelectedId,
    resetComparePlan2SummaryResponse,
} = comparePlansSlice.actions;

export const selectComparePlan1SummaryResponse = (state: RootState) => state.client.comparePlans.comparePlan1SummaryResponse;
export const selectComparePlan2SummaryResponse = (state: RootState) => state.client.comparePlans.comparePlan2SummaryResponse;
export const selectComparePlan1SelectedId = (state: RootState) => state.client.comparePlans.comparePlan1SelectedId;
export const selectComparePlan2SelectedId = (state: RootState) => state.client.comparePlans.comparePlan2SelectedId;
export const selectComparePlanId = (state: RootState) => state.client.comparePlans.comparePlanId;
export const selectDifferenceBetweenPlans = (state: RootState) => state.client.comparePlans.showDifferenceBetweenPlans;
export default comparePlansSlice.reducer;