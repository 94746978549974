import React, {useEffect, useState} from "react";
import FormHeader from "./components/FormHeader";
import {AlertMessage, Button} from "src/components";
import {Route, Switch, useHistory, useParams} from "react-router-dom";
import {RouteWithIdAndFlowchartId} from "../routes/types";
import BasicInformationContent from "./components/BasicInformationContent";
import {EstateFlowchartState, RevocableTrust} from "./EstateFlowchartTypes";
import {estateFlowchartApiClient} from "./EstateFlowchartApiClient";
import {formPage, validateForm} from "./components/validations";
import BeneficiariesFirstDeathContent from "./components/BeneficiariesFirstDeathContent";
import {useAppDispatch, useAppSelector} from "src/store/hooks";
import {getEstateFlowcharts, setEstateFlowchartState} from "./EstateFlowChartSlice";

type AddRevocableTrustProps = {};

const subNavigation = [
    {
        name: "BasicInformation",
        displayName: "Basic Information",
    },
    {
        name: "BeneficiariesAtFirstDeath",
        displayName: "Beneficiaries at First Death",
    },
];

const RevocableTrustForm: React.FC<AddRevocableTrustProps> = () => {
    const history = useHistory();
    const {id, flowchartId, revTrustId} = useParams<RouteWithIdAndFlowchartId>();
    const [showErrorBanner, updateShowErrorBanner] = useState(false);
    const [saveHasBeenClicked, updateSaveHasBeenClicked] = useState(false);
    const [revocableTrust, updateRevocableTrust] = useState({} as RevocableTrust);
    const [isNextDisabled, setNextDisabled] = useState(false);
    const [isPrevDisabled, setPrevDisabled] = useState(false);
    const [activeView, setActiveView] = useState(history.location.pathname.split('/').pop() as String);
    const [formHasBeenEdited, setFormHasBeenEdited] = useState(false);
    const headerTitle =
        flowchartId === "new" ? "Add Revocable Trust" : "Edit Revocable Trust";
    const estateFlowchartsInState = useAppSelector(getEstateFlowcharts);

    const getFlowchart = async (): Promise<EstateFlowchartState[]> => {
        return await estateFlowchartApiClient.getFlowchart(id);
    }
    const dispatch = useAppDispatch();

    const RevocableTrustAlert = () => {
        return (
            <div className="row-container alertBannerContainer">
                <AlertMessage
                    className="display-flex justify-content-right alertBanner"
                    id="alertBanner"
                    fullWidth={false}
                    icon="warning"
                    showAlert={true}
                    showCloseBtn={false}
                    type="error"
                >
                    <b className="alertBannerStyleName">Required fields missing.</b>
                    &nbsp;
                    <b className="font-weight-400">
                        All required fields must be entered to save a flowchart.
                    </b>
                </AlertMessage>
            </div>
        );
    };

    const checkFormIsValid = () => {
        const page = activeView as formPage;
        const {formHasErrors} = validateForm(page, revocableTrust);
        updateShowErrorBanner(formHasErrors);
        return !formHasErrors;
    };

    const handleCreateNewTrust = async (nextClick: boolean): Promise<boolean> => {
        const oldFlowchartIds = estateFlowchartsInState.map(flowchart => flowchart.flowchartId);
        const oldRevTrustIds = estateFlowchartsInState.map(flowchart => flowchart.revocableTrust.revTrustId);
        // TODO: enhance by returning flowchartId and revtrustId from backend on create
        await estateFlowchartApiClient.createRevocableTrust(id, revocableTrust);
        
        const response = await getFlowchart();
        dispatch(setEstateFlowchartState(response));
        
        const newFlowchartIds = response.map(flowchart => flowchart.flowchartId);
        const newRevTrustIds = response.map(flowchart => flowchart.revocableTrust.revTrustId);
        
        const newFlowchartId = newFlowchartIds.find(curFlowchartId => !oldFlowchartIds.includes(curFlowchartId));
        const newRevTrustId = newRevTrustIds.find(curRevTrustId => !oldRevTrustIds.includes(curRevTrustId));
        
        if (newFlowchartId && newRevTrustId && nextClick) {
            history.push(
                `/Profile/${id}/ClientProfile/EstateFlowchart/${newFlowchartId}/RevocableTrust/${newRevTrustId}/` +
                subNavigation[1].name
            );
        }
        
        return true;
    };

    const handleEditTrust = async (): Promise<boolean> => {
        await estateFlowchartApiClient.editRevocableTrust(id, flowchartId, revocableTrust);
        getFlowchart().then((response) => {
            dispatch(setEstateFlowchartState(response));
        });
        return true;
    };

    const onSave = async (nextClick: boolean = false) => {
        if (checkFormIsValid()) {
            let handleSave;
            if (flowchartId === "new") {
                handleSave = handleCreateNewTrust;
            } else {
                handleSave = handleEditTrust;
            }
            handleSave(nextClick).then((data) => {
                if (data && !nextClick) {
                    history.push({
                        pathname: `/Profile/${id}/ClientProfile/EstateFlowchart`,
                    });
                }
            });
        } else {
            updateShowErrorBanner(true);
            updateSaveHasBeenClicked(true);
        }
    };

    
    const onPrevClick = () => {
            const currentIndx = subNavigation.findIndex(item => item.name == activeView)
            setActiveView(subNavigation[currentIndx - 1].name);
            history.push(
                `/Profile/${id}/ClientProfile/EstateFlowchart/${flowchartId}/RevocableTrust/${revTrustId}/` +
                subNavigation[currentIndx - 1].name
            )
    }

    const onNextClick = () => {
        onSave(true);
        if (checkFormIsValid()) {
            const currentIndex = subNavigation.findIndex(item => item.name == activeView)
            setActiveView(subNavigation[currentIndex + 1].name);
            updateSaveHasBeenClicked(false);
            history.push(
                `/Profile/${id}/ClientProfile/EstateFlowchart/${flowchartId}/RevocableTrust/${revTrustId}/` +
                subNavigation[currentIndex + 1].name
            )
        }
    }

    const handleMenuClick = (view: String) => {
        onSave(true);
        if (checkFormIsValid()) {
            setActiveView(view);
            history.push(
                `/Profile/${id}/ClientProfile/EstateFlowchart/${flowchartId}/RevocableTrust/${revTrustId}/` +
                view
            );
            return true;
        }
    };

    const activateMenuItem = (view: String) => {
        return view === activeView ? "sub-nav-item--active" : "sub-nav-item";
    };

    useEffect(() => {
        const isLastView = activeView === subNavigation[subNavigation.length - 1].name;
        const isFirstView = activeView === subNavigation[0].name;
        setNextDisabled(isLastView);
        setPrevDisabled(isFirstView);
        if (estateFlowchartsInState.length == 0) {
            getFlowchart().then((response) => {
                dispatch(setEstateFlowchartState(response));
            });
        }
    }, [activeView]);

    useEffect(() => {
        const initialRevocableTrust: RevocableTrust = estateFlowchartsInState.find(flowchart => flowchart.flowchartId == flowchartId)?.revocableTrust || {} as RevocableTrust;
        const hasBeenEdited: boolean = JSON.stringify(initialRevocableTrust) !== JSON.stringify(revocableTrust);
        setFormHasBeenEdited(hasBeenEdited)
    }, [revocableTrust]);

    return (
        <div className="revocable-trust-form-container">
            <FormHeader title={headerTitle} formHasBeenEdited={formHasBeenEdited}/>
            <div className="revocable-trust-content">
                <div className="sub-nav-container">
                    <ul className="sub-nav-list" data-testid={"sub-nav-list"}>
                        {subNavigation.map((item, index) => (
                            <li
                                className={activateMenuItem(item.name)}
                                onClick={() => handleMenuClick(item.name)}
                                key={index}
                                data-testid={"sub-nav-list-item-" + item.name}
                            >
                                {item.displayName}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="revocable-trust-form">
                    {showErrorBanner && RevocableTrustAlert()}
                    <div
                        className={
                            showErrorBanner
                                ? "form-container form-container-error"
                                : "form-container"
                        }
                    >
                        <Switch>
                            <Route
                                path="/Profile/:id/ClientProfile/EstateFlowchart/:flowchartId/RevocableTrust/:revTrustId/BasicInformation">
                                <BasicInformationContent
                                    saveHasBeenClicked={saveHasBeenClicked}
                                    updatedRevocableTrust={(newValue: RevocableTrust) => updateRevocableTrust(newValue)}
                                    id={id}
                                />
                            </Route>
                            <Route
                                path="/Profile/:id/ClientProfile/EstateFlowchart/:flowchartId/RevocableTrust/:revTrustId/BeneficiariesAtFirstDeath">
                                <BeneficiariesFirstDeathContent
                                    saveHasBeenClicked={saveHasBeenClicked}
                                    updatedRevocableTrust={updateRevocableTrust}
                                />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
            <div className="form-footer textalign-right" data-testid="revtrust-form-footer">
                <div className="prev-btn-container">
                    <Button
                        icon="none"
                        id="prev_page_button"
                        includeRef={false}
                        kind="secondary"
                        onClick={onPrevClick}
                        size="medium"
                        tabIndex={0}
                        disabled={isPrevDisabled}
                        type="button"
                    >PREVIOUS</Button>
                </div>
                <div className="save-next-btn-container">
                    <Button
                        icon="none"
                        id="save_new_client_profile_button"
                        includeRef={false}
                        kind="secondary"
                        onClick={() => onSave()}
                        size="medium"
                        tabIndex={0}
                        type="button"
                    >
                        SAVE
                    </Button>
                    <Button
                        icon="none"
                        id="next_page_button"
                        kind="primary"
                        onClick={onNextClick}
                        disabled={isNextDisabled}
                        size="medium"
                        tabIndex={1}
                        type="button"
                        data-testId="NextPage-btn"
                    >
                        NEXT
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default RevocableTrustForm;
