import React from "react";
import {Dropdown, DropdownItem} from "src/components";
import {Beneficiaries} from "../EstateFlowchartTypes";
import {DropdownOnChangeData} from "../../components/Dropdown/Dropdown";

type CopyButtonProps = {
    className: string;
    beneficiaryInfo: Beneficiaries;
    disabled: boolean;
    onCopyDistribution: (data: DropdownOnChangeData<string>) => void
};

function CopyButtonDropdown({className, beneficiaryInfo, disabled, onCopyDistribution}: CopyButtonProps): JSX.Element {

    const dropdownItemArray = beneficiaryInfo.firstDeath.maritalTrusts?.map((maritalTrust, index) => (
        {
            itemText: maritalTrust.trustName,
            value: maritalTrust.trustName,
            onClick: () => {
                // TODO: Copy the Marital Trust of the existing trust
            },
            disabled: false,
        })) || [];

    return (
        <Dropdown
            className={className}
            alignRight={false}
            buttonKind="borderless"
            dropdownKind="menu"
            defaultText="COPY"
            panelWidth={245}
            disabled={disabled}
            onChange={onCopyDistribution}
        >
            {dropdownItemArray.map((item: any, i: number) => (
                <DropdownItem
                    key={i}
                    itemText={item.itemText}
                    value={item.value}
                    onClick={item.onClick}
                    disabled={item.disabled}
                />
            ))}
        </Dropdown>
    );
}

export default CopyButtonDropdown;
