import React, {useState} from "react";
import ComparePlansHeader from "./ComparePlansHeader";
import ComparePlansContent from "./ComparePlansContent";
import {useDispatch, useSelector} from "react-redux";
import {selectDifferenceBetweenPlans, setDifferenceBetweenPlans} from "./ComparePlansSlice";

interface ComparePlansProps {
    showDeltaBetweenPlans: boolean;
}

export const ComparePlans: React.FC<ComparePlansProps> = () => {
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState<boolean>(false);
    const showDifferenceBetweenPlans = useSelector(selectDifferenceBetweenPlans);

    const handleShowDeltaBetweenPlans = (isChecked: boolean) => {
        dispatch(setDifferenceBetweenPlans(isChecked));
    }

    const handleComparePlanDropDownChange = (isComparePlan2Selected: boolean) => {
        setSelectedValue(isComparePlan2Selected);
    }

    return (
        <div className="compare-plans">
            <ComparePlansHeader value={showDifferenceBetweenPlans} onChange={handleShowDeltaBetweenPlans}
                                selectedValue={selectedValue}/>
            <ComparePlansContent value={showDifferenceBetweenPlans} onChange={handleComparePlanDropDownChange}/>
        </div>
    )
}